<template>
<div>

    <v-snackbar v-if="alert" :timeout="5000" :value="true" :color="alert.color" absolute center rounded="pill" top>
        {{alert.title}}
    </v-snackbar>

    <v-card class="my-card animate__animated animate__fadeIn">
        <v-card-title>
            <div class="accountTitle">เปลี่ยนรหัสผ่าน</div>
        </v-card-title>

        <v-card-text>

            <v-form @submit="onSubmit" class="q-gutter-md nForm">

                <v-text-field v-model="data.old_password" type="password" label="รหัสผ่านปัจุบัน" :rules="[rules.required, rules.counter2]" outlined></v-text-field>

                <v-text-field v-model="data.password" type="password" label="ตั้งรหัสผ่านใหม่ที่ต้องการ" :rules="[rules.required, rules.counter]" maxlength="20" outlined></v-text-field>

                <div v-if="msg" v-html="msg"></div>
                <div class="text-center">

                    <v-progress-circular indeterminate v-if="startEdit" color="primary"></v-progress-circular>
                    <v-btn type="button" color="primary" @click="onSubmit"> บันทึก </v-btn>
                </div>

            </v-form>
        </v-card-text>

    </v-card>

</div>
</template>

<script>
export default {

    data: function () {
        return {

            msg: null,
            startEdit: null,
            profile: null,
            alert:null,

            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length >= 6 || 'อย่างน้อย 6 ตัวอักษรค่ะ',
                counter2: value => value.length >= 4 || 'อย่างน้อย 4 ตัวอักษรค่ะ',
            },

            data: {
                password: '',
                old_password: '',
            }
        };
    },

    methods: {

        onSubmit() {

            if (this.data.password.length < 6 || this.data.old_password.length < 4) {

                this.msg = "รหัสผ่านอย่างน้อย 6 ตัวค่ะ"

            } else {

                let data = this.data
                const formData = new FormData();
                console.log(data)

                for (var key in data) {
                    formData.append(key, data[key]);
                }

                let $vm = this
                $vm.alert = null
                var url = "user/changePassword";
                console.log(url)
                $vm.$http
                    .post(url, this.formData(formData))
                    .then(function (res) {

                        console.log(res)
                        if (res.data.alert) {

                           // $vm.msg = res.data.alert.title
                             $vm.alert = res.data.alert 
                        }

                        if (res.data.old_password_check == 'no') {
                           // $vm.msg = res.data.msg
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }

        }

    }

    ,
    created: function () {

    }
};
</script>

<style>
</style>
